import { getEkomiData } from '~/utils/ekomi';
import { sanitizeItems, getQuickAccessData } from '../hero-push/adapter';

export default async ({ primary }, pageData, context, { documentType }) => {
  const { $i18n, $prismic, $enhancedLinkSerializer } = context;
  const moduldeId = primary.hero_push_default.id;
  const defaultModule = moduldeId && (await $prismic.client.getByID(moduldeId));

  if (!defaultModule?.data) return null;

  const defaultModuleData = defaultModule.data;

  const ekomiAccount =
    defaultModuleData.ekomi_account ??
    defaultModuleData.market?.data?.ekomi_account;

  const withEkomiRating = defaultModuleData.with_ekomi_rating;
  let ekomiData, ekomiInfobulle, ekomiInfobulleWhite;

  if (withEkomiRating) {
    ekomiInfobulle = defaultModuleData.ekomi_rating_infobulle;
    ekomiInfobulleWhite = defaultModuleData.ekomi_rating_infobulle_white;
    ekomiData = await getEkomiData(ekomiAccount, 'reviews', context);
  }

  const heroData = sanitizeItems(
    [defaultModuleData],
    pageData,
    documentType,
    $i18n,
    $enhancedLinkSerializer,
    { withEkomiRating, ekomiData, ekomiInfobulle, ekomiInfobulleWhite }
  )?.[0];

  const defaultQuickAccessData = getQuickAccessData(
    defaultModuleData,
    $enhancedLinkSerializer,
    $i18n
  );

  const defaultModuleProps = {
    narrowHero: defaultModuleData?.narrow_hero,
    primaryColor: defaultModuleData?.primary_color,
    hasFixedButton: primary.fixed_button,
    blackColor: defaultModuleData.text_color === 'black',
    heroData,
    ...defaultQuickAccessData,
  };

  return defaultModuleProps;
};
